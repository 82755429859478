<template>
  <div>
    <!-- <driver-list-add-new
      :is-add-new-driver-sidebar-active.sync="isAddNewDriverSidebarActive"
      :ride-type-options="rideTypeOptions"
      :verification-options="verificationOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <DriversListFilters
      :ride-type-filter.sync="rideTypeFilter"
      :verification-filter.sync="verificationFilter"
      :suspension-filter.sync="suspensionFilter"
      :ride-type-options="rideTypeOptions"
      :verification-options="verificationOptions"
      :suspension-options="suspensionOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Referesh -->
          <b-col cols="12" md="3">
            <b-button variant="primary" @click="refreshData()">
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewDriverSidebarActive = true"
              >
                <span class="text-nowrap">Add Driver</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDriverListTable"
        class="position-relative"
        :sticky-header="true"
        :items="drivers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(show_details)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? "Hide" : "Show" }}
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-tabs content-class="mt-3">
              <b-tab title="Ride" active>
                <div class="text-nowrap">
                  <p v-if="row.item.ride.brand">
                    Brand:
                    <strong>
                      {{ row.item.ride.brand }}
                    </strong>
                  </p>
                  <p v-if="row.item.ride.model">
                    Model:
                    <strong>
                      {{ row.item.ride.model }}
                    </strong>
                  </p>
                  <p v-if="row.item.ride.color">
                    Color:
                    <strong>
                      {{ row.item.ride.color }}
                    </strong>
                  </p>
                  <p v-if="row.item.ride.plateNumber">
                    Plate Number:
                    <strong>
                      {{ row.item.ride.plateNumber }}
                    </strong>
                  </p>
                </div>
              </b-tab>
              <b-tab title="Documents">
                <div class="text-nowrap">
                  <p
                    v-if="
                      row.item.documents.amacPermit &&
                      row.item.documents.amacPermit.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.amacPermit.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                      >Amac
                    </b-link>
                  </p>

                  <p
                    v-if="
                      row.item.documents.doasPermit &&
                      row.item.documents.doasPermit.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.doasPermit.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                      >Driver License</b-link
                    >
                  </p>

                  <p
                    v-if="
                      row.item.documents.vioPermit &&
                      row.item.documents.vioPermit.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.vioPermit.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                      >Vio</b-link
                    >
                  </p>

                  <p
                    v-if="
                      row.item.documents.vehicleLicense &&
                      row.item.documents.vehicleLicense.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.vehicleLicense.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      Vehicle License
                    </b-link>
                  </p>

                  <p
                    v-if="
                      row.item.documents.driversLicense &&
                      row.item.documents.driversLicense.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.driversLicense.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      Driver License
                    </b-link>
                  </p>

                  <p
                    v-if="
                      row.item.documents.proofOfOwnership &&
                      row.item.documents.proofOfOwnership.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.proofOfOwnership.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      Proof of Ownership
                    </b-link>
                  </p>
                  <p
                    v-if="
                      row.item.documents.insuranceCertificate &&
                      row.item.documents.insuranceCertificate.url
                    "
                  >
                    <b-link
                      :href="row.item.documents.insuranceCertificate.url"
                      target="_blank"
                      class="font-weight-bold d-block text-nowrap"
                    >
                      Insurance Certificate
                    </b-link>
                  </p>
                </div>
              </b-tab>
              <b-tab title="Bank Details">
                 <div class="text-nowrap">
                  <p>
                    Account Name:
                    <strong>
                      {{ row.item.bankDetails.accountName }}
                    </strong>
                  </p>
                  <p>
                    Account Number:
                    <strong>
                      {{ row.item.bankDetails.accountNumber }}
                    </strong>
                  </p>
                  <p>
                    Bank:
                    <strong>
                      {{ row.item.bankDetails.bankName }}
                    </strong>
                  </p>
                </div>
              </b-tab>
            </b-tabs>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <!-- Column: User -->
        <template #cell(driver)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="resolvePhoto(data.item.profile.photo)"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveRideTypeVariant(data.item.ride)}`"
                :to="{
                  name: 'apps-drivers-view',
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-drivers-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ title(data.item.fullName) }}
            </b-link>
            <small class="text-mute">{{ data.item.phone }}</small> <br />
            <small class="text-mute">{{ data.item.profile.gender }}</small>
            <br />
            <small class="text-mute"
              >Ride Type:
              <strong> {{ resolveRideType(data.item.ride) }}</strong></small
            >
          </b-media>
        </template>

        <!-- <template #cell(ride)="data">
          <div class="text-nowrap">
            <p v-if="data.item.ride.brand">Brand: <strong>

              {{data.item.ride.brand}}
              </strong>
               </p>
            <p v-if="data.item.ride.model">Model: 
              <strong>
              {{data.item.ride.model}}

              </strong>
            </p>
            <p v-if="data.item.ride.color">Color: 
              <strong>
              {{data.item.ride.color}}

              </strong>
              
              </p>
            <p v-if="data.item.ride.plateNumber">Plate Number: 
              <strong>

              {{data.item.ride.plateNumber}}
              </strong>
              </p>
          </div>
        </template> -->

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveDriverRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveDriverRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.role
            }}</span>
          </div>
        </template>

        <!-- Column: is Suspended -->
        <template #cell(isSuspended)="data">
          <b-badge
            pill
            :variant="`light-${resolveSuspendedStatusVariant(
              data.item.suspensionStatus
            )}`"
            class="text-capitalize"
          >
            {{ data.item.suspensionStatus }}
          </b-badge>
        </template>

        <!-- Column: isVerified -->
        <template #cell(isVerified)="data">
          <b-badge
            pill
            :variant="`light-${resolveVerifiedStatusVariant(
              data.item.verificationStatus
            )}`"
            class="text-capitalize"
          >
            {{ data.item.verificationStatus }}
          </b-badge>
        </template>

        <!-- Column: Created At -->
        <template #cell(createdAt)="data">
          <div v-if="data.item.createdAt" class="text-nowrap">
            <strong>
              {{
                formatDate(Date.parse(data.item.createdAt), {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                })
              }}
            </strong>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button
              @click="showVerificationStatusModal(data.item)"
              v-if="$can(Action.Update, Subject.RIDER)"
            >
              <feather-icon icon="FileTextIcon" />
              <span>Update Verification Status</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button 
              @click="showAccountStatusModal(data.item)"
              v-if="$can(Action.Update, Subject.RIDER)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{data.item.isSuspended ? 'Activate Account' : 'Suspend Account'}}</span>
            </b-dropdown-item-button>

           <!--  <b-dropdown-item :to="{ name: 'apps-drivers-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDrivers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BTabs,
  BTab,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
// import store from '@/store'
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText, formatDate, title } from "@core/utils/filter";
import DriversListFilters from "./DriversListFilters.vue";
import useDriversList from "./useDriversList";
import Ripple from "vue-ripple-directive";
import { Action, Subject } from '@/libs/acl/config'

export default {
  components: {
    DriversListFilters,
    // DriverListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BTabs,
    BTab,
    BFormCheckbox,

    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {};
  },
  methods: {
    showVerificationStatusModal(rider) {
      let status = rider.isVerified ? "Not verified" : "Verified";
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to update ${rider.fullName}'s verification status.`,
          {
            title: "Confirmation",
            size: "sm",
            okVariant: "primary",
            okTitle: status,
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.updateVerificationStatus(rider.token);
          }
        });
    },
    
    showAccountStatusModal(rider) {
      let status = rider.isSuspended ? "Activate" : "Suspend";
      this.$bvModal
        .msgBoxConfirm(
          `Please confirm that you want to update ${rider.fullName}'s account status.`,
          {
            title: "Confirmation",
            size: "sm",
            okVariant: "primary",
            okTitle: status,
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.updateAccountStatus(rider.token);
          }
        });
    },
  },
  setup() {
    onMounted(() => {
      fetchDrivers();
    });
    const isAddNewDriverSidebarActive = ref(false);

    const rideTypeOptions = [
      { label: "Bike", value: "bike" },
      { label: "Car", value: "car" },
      { label: "Truck", value: "truck" },
    ];

    const verificationOptions = [
      { label: "Verified", value: "verified" },
      { label: "Not verified", value: "not verified" },
    ];
    const suspensionOptions = [
      { label: "Suspended", value: "suspended" },
      { label: "Active", value: "active" },
    ];

    const {
      fetchDrivers,
      drivers,
      updateVerificationStatus,
      updateAccountStatus,
      tableColumns,
      perPage,
      currentPage,
      totalDrivers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDriverListTable,
      refetchData,
      refreshData,

      // UI
      resolveDriverRoleVariant,
      resolveDriverRoleIcon,
      resolveDriverStatusVariant,
      resolveSuspendedStatusVariant,
      resolveVerifiedStatusVariant,
      resolveRideTypeVariant,
      resolveRideType,
      resolvePhoto,

      // Extra Filters
      rideTypeFilter,
      verificationFilter,
      suspensionFilter,
    } = useDriversList();

    return {
      // Sidebar
      isAddNewDriverSidebarActive,

      fetchDrivers,
      drivers,
      updateVerificationStatus,
      updateAccountStatus,
      tableColumns,
      perPage,
      currentPage,
      totalDrivers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDriverListTable,
      refetchData,
      refreshData,

      // Filter
      avatarText,
      formatDate,
      title,

      // UI
      resolveDriverRoleVariant,
      resolveDriverRoleIcon,
      resolveDriverStatusVariant,
      resolveSuspendedStatusVariant,
      resolveVerifiedStatusVariant,
      resolveRideTypeVariant,
      resolveRideType,
      resolvePhoto,

      rideTypeOptions,
      verificationOptions,
      suspensionOptions,

      // Extra Filters
      rideTypeFilter,
      verificationFilter,
      suspensionFilter,

      // Authorisation
      Action,
      Subject
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
